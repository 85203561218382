/**
 * This CSS file was made because custom Dropdown Calendar doesn't work with styled-components
 */

:root {
  --primary-color: #3b91b1;
}

.calendar
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 1px solid var(--primary-color);
}

.calendar
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background: var(--primary-color);
}

.calendar .ant-picker-today-btn {
  color: var(--primary-color);
}
